.a,
.logo-icon {
  position: relative;
}
.logo-icon {
  width: 11.88rem;
  height: 3.09rem;
}
.a {
  text-decoration: none;
  line-height: 120%;
  font-weight: 500;
  color: inherit;
}
.item,
.link,
.txt {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}
.item,
.txt {
  flex-direction: row;
  align-items: center;
  justify-content: center;
}
.txt {
  margin: 0;
  width: 25.63rem;
  align-items: flex-start;
  justify-content: space-between;
  text-align: left;
  font-size: var(--h3-size);
  color: var(--black);
  font-family: var(--h3);
}
.link-7-495-230-03-03 {
  text-decoration: none;
  position: relative;
  line-height: 130%;
  text-transform: uppercase;
  font-weight: 700;
  color: inherit;
}
.logo-parent,
.right {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.right {
  justify-content: flex-start;
  gap: 3.38rem;
}
.logo-parent {
  align-self: stretch;
  height: 5.63rem;
  justify-content: space-between;
  padding: var(--padding-xl) 4.63rem;
  box-sizing: border-box;
}
.button,
.div {
  position: relative;
}
.button {
  cursor: pointer;
  border: 0;
  padding: 0;
  background-color: transparent;
  font-size: var(--h1-size);
  line-height: 3.97rem;
  font-weight: 700;
  font-family: var(--h3);
  color: var(--white);
  text-align: left;
  display: flex;
  align-items: center;
  width: 44.38rem;
}
.div {
  line-height: 1.5rem;
  text-transform: uppercase;
  font-weight: 800;
}
.animation,
.animation1 {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}
.animation1 {
  border-radius: var(--br-9xs);
  background-color: var(--red);
  padding: var(--padding-11xl);
}
.animation {
  flex: 1;
  width: 26.25rem;
}
.animation1 div::before{
  content: "Бухгалтерия";
  animation: animate 20s infinite linear running;
  
}
@keyframes animate {
  0% {
    content: "Бухгалтерия";
  }
  25% {
    content: "ЗУП";
  }
  50% {
    content: "УАТ";
  }
  75% {
    content: "Альфа-авто";
  }
  100% {
    content: "Заказные";
  }
}

.offers {
  height: 15.69rem;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 2.5rem;
}
.div1 {
  position: relative;
  font-size: var(--h3-size);
  line-height: 120%;
  font-weight: 500;
  font-family: var(--h3);
  color: var(--white);
  text-align: left;
}
.btn {
  cursor: pointer;
  border: 3px solid var(--blue);
  padding: var(--padding-lg) var(--padding-21xl);
  background-color: var(--dark-blue1);
  border-radius: var(--br-9xs);
  display: none;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}
.left {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 7.63rem 0 var(--padding-11xl);
  gap: 4.25rem;
}
.image-24-icon {
  position: absolute;
  top: 0;
  right: 0;
  width: 32.59rem;
  height: 33.06rem;
  object-fit: cover;
}
.div2 {
  position: absolute;
  top: 25.25rem;
  left: 6.88rem;
  line-height: 120%;
  font-weight: 500;
  display: flex;
  align-items: center;
  width: 18.88rem;
}
.right1 {
  position: relative;
  width: 32.59rem;
  height: 33.06rem;
  font-size: var(--h3-size);
  color: var(--white);
}
.info {
  position: absolute;
  top: calc(50% - 261px);
  left: calc(50% - 610px);
  width: 75.69rem;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
}
.main1,
.top {
  align-self: stretch;
}
.main1 {
  text-decoration: none;
  position: relative;
  background-color: var(--dark-blue1);
  height: 39.38rem;
  font-size: var(--h2-size);
  color: var(--bg);
}
.top {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
}
.b {
  position: relative;
  line-height: 3.97rem;
}
.ttle {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: var(--padding-3xs) var(--padding-3xs) var(--padding-3xs)
    var(--padding-13xl);
}
.div3,
.icon {
  position: relative;
}
.icon {
  width: 3rem;
  height: 3rem;
  overflow: hidden;
  flex-shrink: 0;
}
.div3 {
  flex: 1;
  line-height: 120%;
  font-weight: 500;
}
.div4,
.wrapper {
  align-self: stretch;
}
.wrapper {
  border-right: 1px solid var(--red);
  border-bottom: 1px solid var(--red);
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  padding: 0 var(--padding-3xs) var(--padding-3xs) 0;
}
.div4 {
  position: relative;
  font-size: var(--mini-txt-size);
  line-height: 1.14rem;
  color: var(--black);
}
.card,
.container,
.txt1 {
  display: flex;
  justify-content: flex-start;
}
.txt1 {
  flex: 1;
  flex-direction: column;
  align-items: flex-start;
  gap: var(--gap-5xl);
}
.card,
.container {
  align-self: stretch;
  flex-direction: row;
}
.card {
  height: 9.44rem;
  align-items: flex-start;
  padding: 0 0 var(--padding-xl);
  box-sizing: border-box;
  gap: var(--gap-xl);
}
.container {
  border-right: 1px solid var(--blue);
  border-bottom: 1px solid var(--blue);
  align-items: center;
  padding: 0 var(--padding-3xs) var(--padding-3xs) 0;
}
.p {
  margin: 0;
}
.frame {
  align-self: stretch;
  border-right: 1px solid var(--color-darkorange);
  border-bottom: 1px solid var(--color-darkorange);
  flex-direction: row;
  padding: 0 var(--padding-3xs) var(--padding-3xs) 0;
}
.card-parent,
.frame,
.wrapper1 {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.card-parent {
  flex: 1;
  flex-direction: column;
  gap: var(--gap-11xl);
}
.wrapper1 {
  align-self: stretch;
  border-right: 1px solid var(--color-steelblue);
  border-bottom: 1px solid var(--color-steelblue);
  flex-direction: row;
  padding: 0 var(--padding-3xs) var(--padding-3xs) 0;
}
.cards,
.ttle-parent {
  display: flex;
  align-items: flex-start;
}
.cards {
  align-self: stretch;
  flex-direction: row;
  justify-content: center;
  gap: var(--gap-11xl);
  font-size: var(--h3-size);
  color: var(--dark-blue1);
}
.ttle-parent {
  position: absolute;
  top: -0.62rem;
  left: calc(50% - 645px);
  width: 80.75rem;
  flex-direction: column;
  justify-content: flex-start;
  gap: var(--gap-31xl);
}
.argument {
  align-self: stretch;
  position: relative;
  height: 39.81rem;
  font-size: var(--ttle-size);
}
.title {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: center;
  padding: 0 var(--padding-3xs) 0 var(--padding-13xl);
}
.b2 {
  position: absolute;
  top: 2.5rem;
  left: 1.88rem;
  line-height: 130%;
  text-transform: uppercase;
}
.card-child,
.component-1-icon {
  position: absolute;
  max-width: 100%;
  overflow: hidden;
  max-height: 100%;
}
.card-child {
  height: calc(100% - 94.3px);
  width: calc(100% - 25.5px);
  top: 5.88rem;
  right: 1.56rem;
  bottom: 0.02rem;
  left: 0.03rem;
}
.component-1-icon {
  height: calc(100% - 160px);
  width: 34.92%;
  top: 7.63rem;
  right: 53.65%;
  bottom: 2.38rem;
  left: 11.43%;
}
.div13 {
  position: relative;
  line-height: 120%;
  font-weight: 500;
}
.frame-child {
  position: relative;
  width: 1.5rem;
  height: 1.5rem;
}
.parent {
  position: absolute;
  right: 3.63rem;
  bottom: 2.06rem;
  display: none;
  flex-direction: row;
  align-items: flex-end;
  justify-content: center;
  gap: var(--gap-xs);
}
.card5,
.card6 {
  position: relative;
  background-color: var(--white);
  height: 23.75rem;
  overflow: hidden;
}
.card5 {
  width: 39.38rem;
  flex-shrink: 0;
}
.card6 {
  flex: 1;
}
.cards1,
.offers1 {
  display: flex;
  align-items: flex-start;
}
.cards1 {
  width: 80.56rem;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;
  gap: 1.81rem;
  font-size: var(--h3-size);
}
.offers1 {
  text-decoration: none;
  flex-direction: column;
  justify-content: center;
  gap: var(--gap-91xl);
  font-size: var(--ttle-size);
  color: var(--dark-blue1);
}
.picture-icon {
  position: absolute;
  top: calc(50% - 237.5px);
  left: calc(50% - 237.88px);
  width: 35.36rem;
  height: 29.69rem;
}
.div17 {
  position: relative;
  font-size: var(--btn-size);
  line-height: 120%;
}
.txt6 {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 1.19rem;
}
.input1 {
  border: 1px solid var(--dark-grey);
  background-color: var(--white);
  box-sizing: border-box;
  height: 2.25rem;
  padding: 0.44rem var(--padding-xs) 0.58rem;
}
.input,
.input1,
.input3 {
  align-self: stretch;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}
.input3 {
  border: 1px solid var(--dark-grey);
  background-color: var(--white);
  box-sizing: border-box;
  height: 6.13rem;
  padding: 0.44rem var(--padding-xs) 0.58rem;
}
.input {
  gap: 1.06rem;
}
.div18,
.div19,
.div20 {
  position: absolute;
  font-size: var(--mini-txt-size);
  line-height: 1.14rem;
  text-align: left;
  display: flex;
  align-items: center;
}
.div18 {
  top: 0;
  left: 0;
  font-family: var(--font-montserrat);
  color: #b6b7b8;
  width: 25.75rem;
  height: 1.11rem;
}
.div19,
.div20 {
  font-family: var(--h3);
  color: var(--color-white);
}
.div19 {
  top: 0;
  left: 19.56rem;
  width: 5.81rem;
  height: 1.04rem;
}
.div20 {
  top: 1.04rem;
  left: 0;
  width: 12.11rem;
  height: 1.08rem;
}
.txt7 {
  align-self: stretch;
  flex: 1;
  position: relative;
}
.input-parent {
  width: 25.75rem;
  height: 15.13rem;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-end;
  gap: var(--gap-3xs);
}
.btn1 {
  cursor: pointer;
  border: 0;
  padding: var(--padding-lg) var(--padding-21xl);
  background-color: var(--blue);
  border-radius: var(--br-9xs);
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}
.gform {
  width: 25.75rem;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-end;
  gap: var(--gap-xl);
}
.form {
  position: absolute;
  top: calc(50% - 160.5px);
  left: calc(50% - 610px);
  width: 71.63rem;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}
.connection {
  align-self: stretch;
  position: relative;
  background-color: var(--dark-blue1);
  height: 29.69rem;
  overflow: hidden;
  flex-shrink: 0;
  text-align: left;
  font-size: var(--ttle-size);
  color: var(--white);
  font-family: var(--h3);
}
.map,
.map-icon {
  position: absolute;
  top: 3.81rem;
  width: 53.19rem;
  height: 26.88rem;
}
.map {
  border: 0;
  left: 32.06rem;
}
.map-icon {
  left: calc(50% - 207px);
  object-fit: cover;
}
.contacts-child {
  position: absolute;
  top: 7.06rem;
  left: calc(50% + 172.15px);
  width: 2.54rem;
  height: 2.63rem;
}
.div22 {
  position: relative;
  line-height: 120%;
  font-weight: 500;
  display: flex;
  align-items: center;
  width: 20.81rem;
}
.link-7-495-230-03-031,
.link-7-495-230-03-032 {
  text-decoration: none;
  position: relative;
  line-height: 130%;
  text-transform: uppercase;
  font-weight: 700;
  color: var(--blue);
}
.link-7-495-230-03-032 {
  color: var(--black);
}
.txt8,
.txt9 {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}
.txt9 {
  background-color: var(--bg);
  padding: 3.75rem 4.38rem;
  gap: var(--gap-mini);
  font-size: var(--h3-size);
  color: #000;
}
.txt8 {
  position: absolute;
  top: 3.13rem;
  left: calc(50% - 644px);
  gap: var(--gap-31xl);
}
.contacts {
  align-self: stretch;
  position: relative;
  background-color: var(--white);
  height: 35rem;
  text-align: left;
  font-size: var(--ttle-size);
  color: var(--dark-blue1);
  font-family: var(--h3);
}
.footer-child {
  position: absolute;
  right: 0;
  bottom: 0;
  width: 33.44rem;
  height: 17.75rem;
}
.txt10 {
  position: absolute;
  right: 4.94rem;
  bottom: 5.13rem;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: center;
  gap: var(--gap-mini);
}
.logo-icon1 {
  position: relative;
  width: 16.38rem;
  height: 4.38rem;
}
.div24 {
  position: relative;
  line-height: 1.19rem;
  font-weight: 500;
}
.left1,
.parent3 {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-3xs);
}
.left1 {
  position: absolute;
  top: calc(50% - 42px);
  left: calc(50% - 583.5px);
  flex-direction: column;
  gap: var(--gap-31xl);
  text-align: left;
  font-size: var(--mini-txt-size);
  color: var(--dark-blue);
  font-family: var(--mini-txt);
}
.footer {
  align-self: stretch;
  position: relative;
  background-color: var(--dark-blue1);
  height: 17.75rem;
  overflow: hidden;
  flex-shrink: 0;
  text-align: right;
  color: var(--white);
}
.top-parent {
  position: absolute;
  width: 100%;
  top: 0;
  right: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  gap: var(--gap-91xl);
}
.main {
  position: relative;
  background-color: var(--bg);
  width: 100%;
  height: 261.81rem;
  overflow: hidden;
  text-align: left;
  font-size: var(--h3-size);
  color: var(--black);
  font-family: var(--h3);
}
