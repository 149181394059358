.a, .logo-icon {
  position: relative;
}

.logo-icon {
  width: 11.88rem;
  height: 3.09rem;
}

.a {
  color: inherit;
  font-weight: 500;
  line-height: 120%;
  text-decoration: none;
}

.item, .link, .txt {
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  display: flex;
}

.item, .txt {
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.txt {
  text-align: left;
  font-size: var(--h3-size);
  color: var(--black);
  font-family: var(--h3);
  justify-content: space-between;
  align-items: flex-start;
  width: 25.63rem;
  margin: 0;
}

.link-7-495-230-03-03 {
  text-transform: uppercase;
  color: inherit;
  font-weight: 700;
  line-height: 130%;
  text-decoration: none;
  position: relative;
}

.logo-parent, .right {
  flex-direction: row;
  align-items: center;
  display: flex;
}

.right {
  justify-content: flex-start;
  gap: 3.38rem;
}

.logo-parent {
  padding: var(--padding-xl) 4.63rem;
  box-sizing: border-box;
  justify-content: space-between;
  align-self: stretch;
  height: 5.63rem;
}

.button, .div {
  position: relative;
}

.button {
  cursor: pointer;
  font-size: var(--h1-size);
  font-weight: 700;
  line-height: 3.97rem;
  font-family: var(--h3);
  color: var(--white);
  text-align: left;
  background-color: #0000;
  border: 0;
  align-items: center;
  width: 44.38rem;
  padding: 0;
  display: flex;
}

.div {
  text-transform: uppercase;
  font-weight: 800;
  line-height: 1.5rem;
}

.animation, .animation1 {
  flex-direction: row;
  justify-content: center;
  align-items: center;
  display: flex;
}

.animation1 {
  border-radius: var(--br-9xs);
  background-color: var(--red);
  padding: var(--padding-11xl);
}

.animation {
  flex: 1;
  width: 26.25rem;
}

.animation1 div:before {
  content: "Бухгалтерия";
  animation: 20s linear infinite animate;
}

@keyframes animate {
  0% {
    content: "Бухгалтерия";
  }

  25% {
    content: "ЗУП";
  }

  50% {
    content: "УАТ";
  }

  75% {
    content: "Альфа-авто";
  }

  100% {
    content: "Заказные";
  }
}

.offers {
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 2.5rem;
  height: 15.69rem;
  display: flex;
}

.div1 {
  font-size: var(--h3-size);
  font-weight: 500;
  line-height: 120%;
  font-family: var(--h3);
  color: var(--white);
  text-align: left;
  position: relative;
}

.btn {
  cursor: pointer;
  border: 3px solid var(--blue);
  padding: var(--padding-lg) var(--padding-21xl);
  background-color: var(--dark-blue1);
  border-radius: var(--br-9xs);
  flex-direction: row;
  justify-content: center;
  align-items: center;
  display: none;
}

.left {
  padding: 7.63rem 0 var(--padding-11xl);
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 4.25rem;
  display: flex;
}

.image-24-icon {
  object-fit: cover;
  width: 32.59rem;
  height: 33.06rem;
  position: absolute;
  top: 0;
  right: 0;
}

.div2 {
  align-items: center;
  width: 18.88rem;
  font-weight: 500;
  line-height: 120%;
  display: flex;
  position: absolute;
  top: 25.25rem;
  left: 6.88rem;
}

.right1 {
  font-size: var(--h3-size);
  color: var(--white);
  width: 32.59rem;
  height: 33.06rem;
  position: relative;
}

.info {
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  width: 75.69rem;
  display: flex;
  position: absolute;
  top: calc(50% - 261px);
  left: calc(50% - 610px);
}

.main1, .top {
  align-self: stretch;
}

.main1 {
  background-color: var(--dark-blue1);
  font-size: var(--h2-size);
  color: var(--bg);
  height: 39.38rem;
  text-decoration: none;
  position: relative;
}

.top {
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  display: flex;
}

.b {
  line-height: 3.97rem;
  position: relative;
}

.ttle {
  padding: var(--padding-3xs) var(--padding-3xs) var(--padding-3xs) var(--padding-13xl);
  flex-direction: row;
  justify-content: center;
  align-items: center;
  display: flex;
}

.div3, .icon {
  position: relative;
}

.icon {
  flex-shrink: 0;
  width: 3rem;
  height: 3rem;
  overflow: hidden;
}

.div3 {
  flex: 1;
  font-weight: 500;
  line-height: 120%;
}

.div4, .wrapper {
  align-self: stretch;
}

.wrapper {
  border-right: 1px solid var(--red);
  border-bottom: 1px solid var(--red);
  padding: 0 var(--padding-3xs) var(--padding-3xs) 0;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  display: flex;
}

.div4 {
  font-size: var(--mini-txt-size);
  color: var(--black);
  line-height: 1.14rem;
  position: relative;
}

.card, .container, .txt1 {
  justify-content: flex-start;
  display: flex;
}

.txt1 {
  align-items: flex-start;
  gap: var(--gap-5xl);
  flex-direction: column;
  flex: 1;
}

.card, .container {
  flex-direction: row;
  align-self: stretch;
}

.card {
  padding: 0 0 var(--padding-xl);
  box-sizing: border-box;
  align-items: flex-start;
  gap: var(--gap-xl);
  height: 9.44rem;
}

.container {
  border-right: 1px solid var(--blue);
  border-bottom: 1px solid var(--blue);
  padding: 0 var(--padding-3xs) var(--padding-3xs) 0;
  align-items: center;
}

.p {
  margin: 0;
}

.frame {
  border-right: 1px solid var(--color-darkorange);
  border-bottom: 1px solid var(--color-darkorange);
  padding: 0 var(--padding-3xs) var(--padding-3xs) 0;
  flex-direction: row;
  align-self: stretch;
}

.card-parent, .frame, .wrapper1 {
  justify-content: flex-start;
  align-items: center;
  display: flex;
}

.card-parent {
  gap: var(--gap-11xl);
  flex-direction: column;
  flex: 1;
}

.wrapper1 {
  border-right: 1px solid var(--color-steelblue);
  border-bottom: 1px solid var(--color-steelblue);
  padding: 0 var(--padding-3xs) var(--padding-3xs) 0;
  flex-direction: row;
  align-self: stretch;
}

.cards, .ttle-parent {
  align-items: flex-start;
  display: flex;
}

.cards {
  justify-content: center;
  align-self: stretch;
  gap: var(--gap-11xl);
  font-size: var(--h3-size);
  color: var(--dark-blue1);
  flex-direction: row;
}

.ttle-parent {
  justify-content: flex-start;
  gap: var(--gap-31xl);
  flex-direction: column;
  width: 80.75rem;
  position: absolute;
  top: -.62rem;
  left: calc(50% - 645px);
}

.argument {
  font-size: var(--ttle-size);
  align-self: stretch;
  height: 39.81rem;
  position: relative;
}

.title {
  padding: 0 var(--padding-3xs) 0 var(--padding-13xl);
  flex-direction: row;
  justify-content: center;
  align-items: flex-start;
  display: flex;
}

.b2 {
  text-transform: uppercase;
  line-height: 130%;
  position: absolute;
  top: 2.5rem;
  left: 1.88rem;
}

.card-child, .component-1-icon {
  max-width: 100%;
  max-height: 100%;
  position: absolute;
  overflow: hidden;
}

.card-child {
  width: calc(100% - 25.5px);
  height: calc(100% - 94.3px);
  inset: 5.88rem 1.56rem .02rem .03rem;
}

.component-1-icon {
  width: 34.92%;
  height: calc(100% - 160px);
  inset: 7.63rem 53.65% 2.38rem 11.43%;
}

.div13 {
  font-weight: 500;
  line-height: 120%;
  position: relative;
}

.frame-child {
  width: 1.5rem;
  height: 1.5rem;
  position: relative;
}

.parent {
  justify-content: center;
  align-items: flex-end;
  gap: var(--gap-xs);
  flex-direction: row;
  display: none;
  position: absolute;
  bottom: 2.06rem;
  right: 3.63rem;
}

.card5, .card6 {
  background-color: var(--white);
  height: 23.75rem;
  position: relative;
  overflow: hidden;
}

.card5 {
  flex-shrink: 0;
  width: 39.38rem;
}

.card6 {
  flex: 1;
}

.cards1, .offers1 {
  align-items: flex-start;
  display: flex;
}

.cards1 {
  font-size: var(--h3-size);
  flex-flow: wrap;
  justify-content: flex-start;
  gap: 1.81rem;
  width: 80.56rem;
}

.offers1 {
  justify-content: center;
  gap: var(--gap-91xl);
  font-size: var(--ttle-size);
  color: var(--dark-blue1);
  flex-direction: column;
  text-decoration: none;
}

.picture-icon {
  width: 35.36rem;
  height: 29.69rem;
  position: absolute;
  top: calc(50% - 237.5px);
  left: calc(50% - 237.88px);
}

.div17 {
  font-size: var(--btn-size);
  line-height: 120%;
  position: relative;
}

.txt6 {
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 1.19rem;
  display: flex;
}

.input1 {
  border: 1px solid var(--dark-grey);
  background-color: var(--white);
  box-sizing: border-box;
  padding: .44rem var(--padding-xs) .58rem;
  height: 2.25rem;
}

.input, .input1, .input3 {
  flex-direction: column;
  justify-content: flex-start;
  align-self: stretch;
  align-items: flex-start;
  display: flex;
}

.input3 {
  border: 1px solid var(--dark-grey);
  background-color: var(--white);
  box-sizing: border-box;
  padding: .44rem var(--padding-xs) .58rem;
  height: 6.13rem;
}

.input {
  gap: 1.06rem;
}

.div18, .div19, .div20 {
  font-size: var(--mini-txt-size);
  text-align: left;
  align-items: center;
  line-height: 1.14rem;
  display: flex;
  position: absolute;
}

.div18 {
  font-family: var(--font-montserrat);
  color: #b6b7b8;
  width: 25.75rem;
  height: 1.11rem;
  top: 0;
  left: 0;
}

.div19, .div20 {
  font-family: var(--h3);
  color: var(--color-white);
}

.div19 {
  width: 5.81rem;
  height: 1.04rem;
  top: 0;
  left: 19.56rem;
}

.div20 {
  width: 12.11rem;
  height: 1.08rem;
  top: 1.04rem;
  left: 0;
}

.txt7 {
  flex: 1;
  align-self: stretch;
  position: relative;
}

.input-parent {
  justify-content: flex-end;
  align-items: flex-start;
  gap: var(--gap-3xs);
  flex-direction: column;
  width: 25.75rem;
  height: 15.13rem;
  display: flex;
}

.btn1 {
  cursor: pointer;
  padding: var(--padding-lg) var(--padding-21xl);
  background-color: var(--blue);
  border-radius: var(--br-9xs);
  border: 0;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  display: flex;
}

.gform {
  justify-content: flex-end;
  align-items: flex-start;
  gap: var(--gap-xl);
  flex-direction: column;
  width: 25.75rem;
  display: flex;
}

.form {
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 71.63rem;
  display: flex;
  position: absolute;
  top: calc(50% - 160.5px);
  left: calc(50% - 610px);
}

.connection {
  background-color: var(--dark-blue1);
  text-align: left;
  font-size: var(--ttle-size);
  color: var(--white);
  font-family: var(--h3);
  flex-shrink: 0;
  align-self: stretch;
  height: 29.69rem;
  position: relative;
  overflow: hidden;
}

.map, .map-icon {
  width: 53.19rem;
  height: 26.88rem;
  position: absolute;
  top: 3.81rem;
}

.map {
  border: 0;
  left: 32.06rem;
}

.map-icon {
  object-fit: cover;
  left: calc(50% - 207px);
}

.contacts-child {
  width: 2.54rem;
  height: 2.63rem;
  position: absolute;
  top: 7.06rem;
  left: calc(50% + 172.15px);
}

.div22 {
  align-items: center;
  width: 20.81rem;
  font-weight: 500;
  line-height: 120%;
  display: flex;
  position: relative;
}

.link-7-495-230-03-031, .link-7-495-230-03-032 {
  text-transform: uppercase;
  color: var(--blue);
  font-weight: 700;
  line-height: 130%;
  text-decoration: none;
  position: relative;
}

.link-7-495-230-03-032 {
  color: var(--black);
}

.txt8, .txt9 {
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  display: flex;
}

.txt9 {
  background-color: var(--bg);
  gap: var(--gap-mini);
  font-size: var(--h3-size);
  color: #000;
  padding: 3.75rem 4.38rem;
}

.txt8 {
  gap: var(--gap-31xl);
  position: absolute;
  top: 3.13rem;
  left: calc(50% - 644px);
}

.contacts {
  background-color: var(--white);
  text-align: left;
  font-size: var(--ttle-size);
  color: var(--dark-blue1);
  font-family: var(--h3);
  align-self: stretch;
  height: 35rem;
  position: relative;
}

.footer-child {
  width: 33.44rem;
  height: 17.75rem;
  position: absolute;
  bottom: 0;
  right: 0;
}

.txt10 {
  justify-content: center;
  align-items: flex-end;
  gap: var(--gap-mini);
  flex-direction: column;
  display: flex;
  position: absolute;
  bottom: 5.13rem;
  right: 4.94rem;
}

.logo-icon1 {
  width: 16.38rem;
  height: 4.38rem;
  position: relative;
}

.div24 {
  font-weight: 500;
  line-height: 1.19rem;
  position: relative;
}

.left1, .parent3 {
  justify-content: flex-start;
  align-items: flex-start;
  gap: var(--gap-3xs);
  flex-direction: row;
  display: flex;
}

.left1 {
  gap: var(--gap-31xl);
  text-align: left;
  font-size: var(--mini-txt-size);
  color: var(--dark-blue);
  font-family: var(--mini-txt);
  flex-direction: column;
  position: absolute;
  top: calc(50% - 42px);
  left: calc(50% - 583.5px);
}

.footer {
  background-color: var(--dark-blue1);
  text-align: right;
  color: var(--white);
  flex-shrink: 0;
  align-self: stretch;
  height: 17.75rem;
  position: relative;
  overflow: hidden;
}

.top-parent {
  justify-content: flex-start;
  align-items: center;
  gap: var(--gap-91xl);
  flex-direction: column;
  width: 100%;
  display: flex;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
}

.main {
  background-color: var(--bg);
  text-align: left;
  font-size: var(--h3-size);
  color: var(--black);
  font-family: var(--h3);
  width: 100%;
  height: 261.81rem;
  position: relative;
  overflow: hidden;
}

/*# sourceMappingURL=index.21077cf7.css.map */
